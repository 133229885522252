


 export const route_default= [
      {
        path: '',
        name: 'Home',
        component: () => import( '../views/Default/Home.vue'),

      },
      {
        path:'hoi-dap',
        name:'question',
        component:() => import("../views/Default/Pages/Question.vue")
      },
      {
        path:'y-kien-gop-y',
        name:'awnser',
        component:() => import("../views/Default/Pages/Awnser.vue")
      },
      {
        path:'so-do-tuyen-xe-bus',
        name:'awnser',
        component:() => import("../views/Default/Pages/Bus.vue")
      },
      {
        path:'lien-he',
        name:'contact',
        component:() => import("../views/Default/Pages/Contact.vue")
      },
      
      {
        path:'tra-cuu-hoa-don',
        name:'order',
        component:() => import("../views/Default/Order.vue")
      },
      {
        path:'thue-quang-cao',
        name:'rent',
        component:() => import("../views/Default/Rent.vue")
      },
      {
        path:'tim-kiem',
        name:'search',
        component:() => import("../views/Default/Search.vue")
      },

      {
        path:'album',
        name:'album',
        component:() => import("../views/Default/Album/Album.vue")
      },
      {
        path:'album/:slug',
        name:'albumdetail',
        component:() => import("../views/Default/Album/Detail.vue")
      },


      {
        path:'cho-thue-mat-bang',
        name:'booth',
        component:() => import("../views/Default/Booth/Booth.vue")
      },
      {
        path:'cho-thue-mat-bang/:slug',
        name:'booth.detail',
        component:() => import("../views/Default/Booth/Detail.vue")
      },


      {
        path:'cong-ty-van-tai',
        name:'business',
        component:() => import("../views/Default/Business/Business.vue")
      },
       {
        path:'tuyen-xe',
        name:'vehicle',
        component:() => import("../views/Default/Business/Vehicle.vue")
      },
     

     


      {
        path:'trang/:slug',
        name:'page.detail',
        component:() => import("../views/Default/Pages/Detail.vue")
      },

      {
        path:'ben-xe-mien-dong/:slug',
        name:'news.detail',
        component:() => import("../views/Default/News/Detail.vue")
      },
      {
        path:':slug',
        name:'news.cate',
        component:() => import("../views/Default/News/Cate.vue")
      },

      // ,
      // {
      //   path: 'cafes/:slug/edit',
      //   name: 'editcafe',
      //   component: Vue.component( 'EditCafe', require( '../views/Default/Home.vue' ) ),
      //   beforeEnter: requireAuth,
      //   meta: {
      //     permission: 'user'
      //   }
      // },
      // {
      //   path: 'profile',
      //   name: 'profile',
      //   component: Vue.component( 'Profile', require( '../views/Default/Home.vue' ) ),
      //   beforeEnter: requireAuth,
      //   meta: {
      //     permission: 'user'
      //   }
      // },
      /*
        Catch Alls
      */
     // { path: '_=_', redirect: '/' }
   ]

    


