import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta';


Vue.use(VueRouter);
Vue.use(Meta);

// var routes_default = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

// var routes_admin=[
//      {
//         path: '/login',
//         name: 'Login',
//         component: () => import( '../views/Admin/User/Login.vue')
//     }

// ];

//const routes =routes_default.concat(routes_admin);


import { route_admin } from "./route_admin";

import { route_default } from "./route_default";

import { i18n }  from "../main";

const routes =  [
  {
    
    path: '/',
    redirect: { name: 'Home' },
    component: () => import( '../views/Default/Home.vue'),

     beforeEnter: (to, from, next) => { 
     
       i18n.locale = 'vn';
     
       return next() 
    }
    
  },
  {
    path: '/login/',
    name: 'admin.login',
    component: () => import( '../layouts/Login.vue'),
     children: [

       {
          path: 'username',
          name: 'admin.login',
          component: () =>  import( '../views/Admin/User/Login.vue'),
        
        },

    ]
  
  },
  {

    path: '/:locale',
    redirect: { name: 'Bến xe Miền Đông' },
    name: 'Layout',
    component: () => import( '../layouts/Layout.vue'),
     beforeEnter: (to, from, next) => { 
     
       i18n.locale = to.params.locale || 'vn';
       console.log("OK",i18n.locale);
       if(to.params.locale==''){

          return next('vn');
       }
       return next() 
    },
    children: route_default
  }
  ,
  {
    path: '/admin/',
    name: 'admin',
    component: () => import( '../layouts/Admin.vue'),
   
   // beforeEnter: requireAuth,
    meta: {
      permission: 'owner'
    },
    children: route_admin
  }
 

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   alert(locale)
//   i18n.locale = to.params.locale || 'vn'
//   return next(i18n.locale)
// })

export default router


