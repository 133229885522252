


const requireAuth = (to, from, next) => {
    
     // if (localStorage.mylogin) {
     //         console.log("BUITIN", JSON.parse(localStorage.mylogin ) );
     //          this.$session.set("isLogin", JSON.parse(localStorage.mylogin ) );

     //       }

     if(localStorage.Roles){
          
          let Roles=localStorage.Roles.split(",");

          let name = to.name.split(".")[1];
           console.log(name);
           if( name =='user' || name=="roles" || name=='general'){
              next();

           }else{
               let r=[];
                for (var i = Roles.length - 1; i >= 0; i--) {
                    
                    r.push(Roles[i].split(".")[1]);
                 } 
                 if(r.length>0){
                    if(r.indexOf(name) > -1){
                        next();
                    }else{
                       alert("Bạn không có phân quyền vào mục này."); 
                       next("/admin/general/info");
                    
                   
                    }
                }
           }
          


           


     }
           
  if (to.matched.some(record => record.meta.backend)) {
    //require('../static/backend.css')
    next()
  } else {
    //   require('../static/frontend.css')
    next();
  }
}

export const route_admin = [

  /*
    Catch Alls
      { path: '_=_', redirect: '/' }
  */
  //Station 

  {
    
    path: 'general/station',
    name: 'admin.general.station',
    component: () => import('../views/Admin/Main/General/Station.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
     
    }
  },
  {
    
    path: 'general/info',
    name: 'admin.general.info',
    component: () => import('../views/Admin/Main/General/Info.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
     
    }
  },

  {
    
    path: 'station/lists',
    name: 'admin.station.lists',
    component: () => import('../views/Admin/Main/Station/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
     
    }
  },
  {
    path: 'station/add',
    name: 'admin.station.add',
    component: () => import('../views/Admin/Main/Station/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'station/edit/:id',
    name: 'admin.station.edit',
    component: () => import('../views/Admin/Main/Station/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },

   //tripassigment 
  {
    
    path: 'tripassigment/lists',
    name: 'admin.tripassigment.lists',
    component: () => import('../views/Admin/Main/tripassigment/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'tripassigment/add',
    name: 'admin.tripassigment.add',
    component: () => import('../views/Admin/Main/tripassigment/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'tripassigment/edit/:id',
    name: 'admin.tripassigment.edit',
    component: () => import('../views/Admin/Main/tripassigment/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
       is_side: 1,
       isRole:[1,2]
    }
  },

    //vehicle 
  {
    label:"Quản lý tuyến xe",
    path: 'vehicle/lists',
    name: 'admin.vehicle.lists',
    component: () => import('../views/Admin/Main/Vehicle/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'vehicle/add',
    name: 'admin.vehicle.add',
    component: () => import('../views/Admin/Main/Vehicle/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
   {
    path: 'vehicle/export',
    name: 'admin.vehicle.export',
    component: () => import('../views/Admin/Main/Vehicle/Export.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
   {
    path: 'vehicle/import',
    name: 'admin.vehicle.import',
    component: () => import('../views/Admin/Main/Vehicle/Import.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'vehicle/edit/:id',
    name: 'admin.vehicle.edit',
    component: () => import('../views/Admin/Main/Vehicle/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
       is_side: 1,
       isRole:[1,2]
    }
  },

  

    //Location 
  {
    label:"Tỉnh/ Thành phố",
    path: 'location/lists',
    name: 'admin.location.lists',
    component: () => import('../views/Admin/Main/location/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'location/add',
    name: 'admin.location.add',
    component: () => import('../views/Admin/Main/location/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'location/edit/:id',
    name: 'admin.location.edit',
    component: () => import('../views/Admin/Main/location/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
       is_side: 1,
       isRole:[1,2]
    }
  },
   {
    path: 'location/import',
    name: 'admin.location.add',
    component: () => import('../views/Admin/Main/location/Import.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  

    //Business 
  {
    label:"Doanh nghiệp vận tải",
    path: 'business/lists',
    name: 'admin.business.lists',
    component: () => import('../views/Admin/Main/Business/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'business/add',
    name: 'admin.business.add',
    component: () => import('../views/Admin/Main/Business/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'business/edit/:id',
    name: 'admin.business.edit',
    component: () => import('../views/Admin/Main/Business/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
       is_side: 1,
       isRole:[1,2]
    }
  },
  {
    path: 'business/import',
    name: 'admin.business.import',
    component: () => import('../views/Admin/Main/Business/Import.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },

    //Bus 
  {
    label:"Tuyến xe bus",
    path: 'bus/lists',
    name: 'admin.bus.lists',
    component: () => import('../views/Admin/Main/Bus/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'bus/add',
    name: 'admin.bus.add',
    component: () => import('../views/Admin/Main/Bus/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  {
    path: 'bus/edit/:id',
    name: 'admin.bus.edit',
    component: () => import('../views/Admin/Main/Bus/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
       is_side: 1,
       isRole:[1,2]
    }
  },
   {
    path: 'bus/import',
    name: 'admin.bus.import',
    component: () => import('../views/Admin/Main/Bus/Import.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2]
    }
  },
  

  {
    path: 'bus/crontab',
    name: 'admin.bus.crontab',
    component: () => import('../views/Admin/Main/Bus/Crontab.vue'),

    
   
  },
  




//OTHER MENU is_side=2


  {
    label:"Thông tin chung",
    path: 'index',
    name: 'admin.index',
    component: () => import('../views/Admin/Index/Index.vue'),
    children: [
      {
        path: 'index',
        name: 'admin.index',
        component: () => import('../views/Admin/Index/Index.vue'),
        beforeEnter: requireAuth,
        meta: {
          permission: 'user',
          is_side: 2,
          isRole:[1]
        }
      }
    ]
  },

  //BOOTH
  {
    label:"Thuê mặt bằng",
    path: 'booth/lists',
    name: 'admin.booth.lists',
    component: () => import('../views/Admin/General/Booths/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },
  {
    path: 'booth/add',
    name: 'admin.booth.add',
    component: () => import('../views/Admin/General/Booths/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },
  {
    path: 'booth/edit/:id',
    name: 'admin.news.booth',
    component: () => import('../views/Admin/General/Booths/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },
   {
    path: 'booth/import',
    name: 'admin.booth.import',
    component: () => import('../views/Admin/General/Booths/Import.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },

  //TICKET
  {
    label:"Thuê quầy vé",
    path: 'ticket/lists',
    name: 'admin.ticket.lists',
    component: () => import('../views/Admin/General/Ticket/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },
  {
    path: 'ticket/add',
    name: 'admin.ticket.add',
    component: () => import('../views/Admin/General/Ticket/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },
  {
    path: 'ticket/edit/:id',
    name: 'admin.news.ticket',
    component: () => import('../views/Admin/General/Ticket/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },
  {
    path: 'ticket/import',
    name: 'admin.ticket.import',
    component: () => import('../views/Admin/General/Ticket/Import.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 1,
      isRole:[1,2,3]
    }
  },

  //NEWS
  {
    label:" Quản lý tin tức",
    path: 'news/lists',
    name: 'admin.news.lists',
    component: () => import('../views/Admin/General/News/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'news/add',
    name: 'admin.news.add',
    component: () => import('../views/Admin/General/News/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'news/edit/:id',
    name: 'admin.news.edit',
    component: () => import('../views/Admin/General/News/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },

  //CATEGORY 
  {
    path: 'category/add',
    name: 'admin.category.add',
    component: () => import('../views/Admin/General/Category/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    label:"Doanh mục tin tức",
    path: 'category/lists',
    name: 'admin.category.lists',
    component: () => import('../views/Admin/General/Category/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'category/edit/:id',
    name: 'admin.category.edit',
    component: () => import('../views/Admin/General/Category/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },

  //DEPARTMENT 
  {
    label:"Quản lý Phòng ban",
    path: 'department/lists',
    name: 'admin.department.lists',
    component: () => import('../views/Admin/General/Department/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'department/add',
    name: 'admin.department.add',
    component: () => import('../views/Admin/General/Department/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'department/edit/:id',
    name: 'admin.department.edit',
    component: () => import('../views/Admin/General/Department/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },



  //Slideshow 
  {
    label:"Quản lý Slideshow",
    path: 'slideshow/lists',
    name: 'admin.slideshow.lists',
    component: () => import('../views/Admin/General/Slideshow/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'slideshow/add',
    name: 'admin.slideshow.add',
    component: () => import('../views/Admin/General/Slideshow/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'slideshow/edit/:id',
    name: 'admin.slideshow.edit',
    component: () => import('../views/Admin/General/Slideshow/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },


  //banner 
  {
    label:"Quản lý Banner",
    path: 'banner/lists',
    name: 'admin.banner.lists',
    component: () => import('../views/Admin/General/Banner/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'banner/add',
    name: 'admin.banner.add',
    component: () => import('../views/Admin/General/Banner/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'banner/edit/:id',
    name: 'admin.banner.edit',
    component: () => import('../views/Admin/General/Banner/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },


  //ALBUM 
  {
    label:"Quản lý Albums ",
    path: 'albums/lists',
    name: 'admin.albums.lists',
    component: () => import('../views/Admin/General/Albums/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'albums/add',
    name: 'admin.albums.add',
    component: () => import('../views/Admin/General/Albums/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'albums/edit/:id',
    name: 'admin.albums.edit',
    component: () => import('../views/Admin/General/Albums/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },


  //PAGE 
  {
    label:"Quản lý Trang",
    path: 'page/lists',
    name: 'admin.page.lists',
    component: () => import('../views/Admin/General/Pages/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'page/add',
    name: 'admin.page.add',
    component: () => import('../views/Admin/General/Pages/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'page/edit/:id',
    name: 'admin.page.edit',
    component: () => import('../views/Admin/General/Pages/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },

  

   {
    label:"Ý kiến khách hàng",
    path: 'commentus/lists',
    name: 'admin.commentus.lists',
    component: () => import('../views/Admin/General/Commentus/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'commentus/edit/:id',
    name: 'admin.commentus.edit',
    component: () => import('../views/Admin/General/Commentus/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },


   {
    label:"Ý kiến Thuê Quảng Cáo",
    path: 'advertising/lists',
    name: 'admin.advertising.lists',
    component: () => import('../views/Admin/General/Advert/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },


  //CONTACT 
  {
    label:"Quản lý liên hệ",
    path: 'contact/lists',
    name: 'admin.contact.lists',
    component: () => import('../views/Admin/General/Contact/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'contact/add',
    name: 'admin.contact.add',
    component: () => import('../views/Admin/General/Contact/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'contact/edit/:id',
    name: 'admin.contact.edit',
    component: () => import('../views/Admin/General/Contact/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },

  //QUESTION 
  {
    label:"Quản lý hỏi đáp ",
    path: 'question/lists',
    name: 'admin.question.lists',
    component: () => import('../views/Admin/General/Question/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'question/add',
    name: 'admin.question.add',
    component: () => import('../views/Admin/General/Question/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'question/edit/:id',
    name: 'admin.question.edit',
    component: () => import('../views/Admin/General/Question/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },



  //QUESTIONRATE 
  {
    path: 'questionrate/lists',
    name: 'admin.questionrate.lists',
    component: () => import('../views/Admin/General/Questionrate/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'questionrate/add',
    name: 'admin.questionrate.add',
    component: () => import('../views/Admin/General/Questionrate/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },
  {
    path: 'questionrate/edit/:id',
    name: 'admin.questionrate.edit',
    component: () => import('../views/Admin/General/Questionrate/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2,3]
    }
  },

  //SUPPLIER 
  {
    label:"Đối tác khách hàng",
    path: 'supplier/lists',
    name: 'admin.supplier.lists',
    component: () => import('../views/Admin/General/Supplier/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'supplier/add',
    name: 'admin.supplier.add',
    component: () => import('../views/Admin/General/Supplier/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'supplier/edit/:id',
    name: 'admin.supplier.edit',
    component: () => import('../views/Admin/General/Supplier/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },


  //CONNECTION 
  {
    label:"Liên kết website",
    path: 'connection/lists',
    name: 'admin.connection.lists',
    component: () => import('../views/Admin/General/Connection/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'connection/add',
    name: 'admin.connection.add',
    component: () => import('../views/Admin/General/Connection/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },
  {
    path: 'connection/edit/:id',
    name: 'admin.connection.edit',
    component: () => import('../views/Admin/General/Connection/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1,2]
    }
  },

  //USER 
  {
    path: 'user/lists',
    name: 'admin.user.lists',
    component: () => import('../views/Admin/General/User/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },
  {
    path: 'user/add',
    name: 'admin.user.add',
    component: () => import('../views/Admin/General/User/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },
  {
    path: 'user/edit/:id',
    name: 'admin.user.edit',
    component: () => import('../views/Admin/General/User/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },

  //ROLES 
  {
    path: 'roles/lists',
    name: 'admin.roles.lists',
    component: () => import('../views/Admin/General/Roles/List.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },
  {
    path: 'roles/add',
    name: 'admin.roles.add',
    component: () => import('../views/Admin/General/Roles/Add.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },
  {
    path: 'roles/edit/:id',
    name: 'admin.roles.edit',
    component: () => import('../views/Admin/General/Roles/Edit.vue'),

    beforeEnter: requireAuth,
    meta: {
      permission: 'user',
      is_side: 2,
      isRole:[1]
    }
  },


];