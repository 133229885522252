

import Vue from 'vue';


import VueI18n from 'vue-i18n'

import messages from './lang'

import VueSession from 'vue-session';



Vue.config.productionTip = false

Vue.use(VueI18n);



export const i18n = new VueI18n({
  locale: 'vn',
  fallbackLocale: 'vn',
  messages
})


import router from "./router/index";

import App from "./App";

import VueSweetalert2 from 'vue-sweetalert2';

import CKEditor from 'ckeditor4-vue';



import { ValidationObserver,ValidationProvider } from 'vee-validate';

import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";


Vue.component('FormValidate', ValidationObserver);
Vue.component("InputValidate",ValidationProvider);


Vue.component('MyLoadTheme', SkeletonTheme);
Vue.component("MyLoading",Skeleton);



Vue.use(CKEditor);




Vue.use(VueSweetalert2);

Vue.use(VueSession);

window.my_api = window.location.protocol+"//api.benxemiendong.com.vn/";

window.my_url = window.location.protocol+"//api.benxemiendong.com.vn/Images/";


window.send_mail = window.location.protocol+"//benxemiendong.com.vn/SendMail/";



new Vue({
  router,
  i18n,
  render: h => h(App)

}).$mount('#app')

